<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="分店"
                                    search
                                    searchPlaceholder="搜尋分店"
                                    placeholder="請選取分店"
                                    @getValue="get_inventory_list"
                                    :options="option.storeList" />
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-select label="盤點時間"
                                    @getValue="val=>search.id_inventory=val"
                                    :options="option.inventoryList" />
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search_inventory();">
                            <i class="fa fa-search"></i>
                            查&nbsp;&nbsp;詢
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>商品名稱</th>
                            <th>庫存數量</th>
                            <th>盤點數量</th>
                            <th nowrap>狀態</th>
                            <th>時間</th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody v-show="inventory_item_list.length>0">
                        <tr v-for="(p,pk) in inventory_item_list"
                            :key="`p_${pk}`">
                            <td data-title="#"
                                scope="row">{{pk+1}}</td>
                            <td data-title="商品名稱">
                                {{p.prod_name}}<br>編號: {{p.prod_code}}
                                <div v-show="item_history_list[p.id_product].length > 1">
                                    <mdb-btn color="blue-grey"
                                             size="sm"
                                             @click="show_item_history(p.id_product);">盤點紀錄</mdb-btn>
                                </div>
                            </td>
                            <td data-title="庫存數量">{{p.num_old}}</td>
                            <td data-title="盤點數量">{{p.num}}</td>
                            <td data-title="狀態">
                                <div v-show="!p.show">
                                    <span class="green-text"
                                          v-show="p.num > p.num_old">盤盈</span>
                                    <span class="red-text"
                                          v-show="p.num_old > p.num">盤損</span>
                                    <span v-show="p.num_old == p.num">正常</span>
                                </div>
                            </td>
                            <td data-title="時間">{{p.ctime}}</td>
                        </tr>
                    </tbody>
                    <tbody v-show="inventory_item_list.length==0">
                        <tr>
                            <td colspan="6">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbBtn,
  mdbRow,
  mdbCol,
  mdbSelect,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbSelect,
  },
  data() {
    return {
      option: {
        storeList: [],
        inventoryList: [],
      },
      storeKeyList: {},
      productKeyList: {},
      inventoryList: [],
      inventoryItemList: [],
      search: {
        id_store: "",
        id_inventory: "",
      },
    };
  },
  computed: {
    inventory_item_list() {
      let vue = this,
        prod_ids = [],
        output = [];
      vue.inventoryItemList.map((item) => {
        if (prod_ids.indexOf(item.id_product) == -1) {
          prod_ids.push(item.id_product);
          output.push(item);
        }
      });
      return output;
    },
    item_history_list() {
      let vue = this,
        output = {};
      vue.inventoryItemList.map((item) => {
        if (!output[item.id_product]) {
          output[item.id_product] = [];
        }
        output[item.id_product].push(item);
      });
      return output;
    },
  },
  mounted() {
    console.clear();
    this.get_data();
  },
  updated() {},
  methods: {
    // 取得盤點單資料
    get_data() {
      let vue = this,
        query = [
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
          {
            name: "inventory",
            data: {
              status: {
                type: 0,
                value: "1",
              },
            },
            sort: {
              id: 1,
            },
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          // 分店資料
          res.data.storeList.forEach((item) => {
            vue.storeKeyList[item.id] = item;
          });
          vue.option.storeList = res.data.storeList.map((item) => {
            item.value = item.id;
            item.text = item.name;
            item.selected = vue.$store.state.id_store.indexOf(item.id) > -1;
            return item;
          });
          // 盤點單資料
          vue.inventoryList = res.data.inventoryList;
          vue.get_inventory_list();
        });
    },
    // 取得盤點單選項資料
    get_inventory_list() {
      let vue = this,
        inventoryList = {};
      // 取已選分店
      vue.option.storeList.forEach((item) => {
        if (item.selected) {
          vue.search.id_store = item.id;
        }
      });
      // 取分店盤點單資料
      inventoryList = vue.inventoryList.filter((item) => {
        return item.id_store == vue.search.id_store;
      });
      vue.option.inventoryList = inventoryList.map((item) => {
        item.value = item.id;
        item.text = item.day;
        return item;
      });
      // 取得最新盤點單清單
      if (vue.option.inventoryList.length > 0) {
        vue.option.inventoryList[0].selected = true;
        vue.search.id_inventory = vue.option.inventoryList[0].id;
        vue.search_inventory();
      }
    },
    // 查詢盤點單清單資料
    search_inventory() {
      let vue = this,
        query = [
          {
            name: "product",
            data: {},
          },
          {
            name: "inventory_item",
            data: {
              id_inventory: {
                type: 0,
                value: vue.search.id_inventory,
              },
            },
            sort: {
              id: 1,
            },
          },
        ];
      if (vue.search.id_inventory) {
        vue.$store
          .dispatch("get_form", {
            payload: {
              url: `data/get_data/?query=${JSON.stringify(query)}`,
            },
          })
          .then((res) => {
            // 產品資料
            res.data.productList.map((item) => {
              vue.productKeyList[item.id] = item;
            });
            // 已盤點單清單資料
            vue.inventoryItemList = res.data.inventory_itemList.map((item) => {
              item.prod_name = vue.productKeyList[item.id_product]
                ? vue.productKeyList[item.id_product].name
                : "";
              item.prod_code = vue.productKeyList[item.id_product]
                ? vue.productKeyList[item.id_product].code
                : "";
              item.num = parseInt(item.num);
              item.num_old = parseInt(item.num_old);
              return item;
            });
          });
      }
    },
    show_item_history(pid) {
      let vue = this,
        title = vue.item_history_list[pid][0].prod_name,
        html = "";
      html += '<table class="table table-striped">';
      html += "<thead>";
      html += " <tr>";
      html += "   <th>庫存數量</th>";
      html += "   <th>盤點數量</th>";
      html += "   <th>狀態</th>";
      html += "   <th>時間</th>";
      html += " </tr>";
      html += "</thead>";
      html += "<tbody>";
      vue.item_history_list[pid].forEach((item) => {
        html += " <tr>";
        html += "   <td>" + item.num_old + "</td>";
        html += "   <td>" + item.num + "</td>";
        if (item.num > item.num_old) {
          html += '   <td><span class="green-text">盤盈</span></td>';
        } else if (item.num_old > item.num) {
          html += '   <td><span class="red-text">盤損</span></td>';
        } else {
          html += "   <td><span>正常</span></td>";
        }
        html +=
          '   <td align="left" style="width: 100px;">' + item.ctime + "</td>";
        html += " </tr>";
      });
      html += "</tbody>";
      html += "</table>";

      vue.$swal.fire({
        title: title,
        html: html,
        showConfirmButton: false,
        showCloseButton: true,
      });
    },
  },
};
</script>